import React from "react"
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import FAQs from '../components/FAQs'
import { Link } from "gatsby"

import PRICING from '../json/faqs/pricing.json'
import FEATURES from '../json/faqs/features.json'
import SECURITY from '../json/faqs/security.json'
import HYBRID from '../json/faqs/hybrid.json'


export default function FAQsPage() {
  const root = useClasses()
  return (
    <Layout>
      <SEO title='FAQs'/>
      <main className="main" ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">FAQs</h1>
        <h2 className="subtitle">You have questions, we have answers (most of the time!)</h2>

        <div className="subheading mt-30" id="plans">Pricing</div>
        <div className="heading">Common Plan & Pricing Questions</div>
        <div className="description">
          <p>We offer three plans that cover most needs <Link to='/plans'>here</Link>.</p>
        </div>
        <FAQs faqs={PRICING}/>

        <div className="subheading mt-30" id="features">Features</div>
        <div className="heading">Common Features & Functonalities Questions</div>
        <div className="description">
          <p>
            More information can be found on our <Link to="/features">Features</Link> page.
          </p>
          <p>
            You have to see it to believe it! Contact us for a <Link to='/demo'>Demo</Link> now and you won't look back.
          </p>
        </div>
        <FAQs faqs={FEATURES}/>

        <div className="subheading mt-30" id="security">Security</div>
        <div className="heading">Common Security & Privacy Questions</div>
        <div className="description">
          <p>Security is built-in by design, not an afterthought! Check out our <Link to='/security'>Security</Link> page for more information.</p>
        </div>
        <FAQs faqs={SECURITY}/>


        <div className="subheading mt-30" id="hybrid">Hybrid Hosting</div>
        <div className="heading">Common Hybrid Hosting Questions</div>
        <div className="description">
          <p>For more information please visit the <Link to='/hybrid'>Hybrid Hosting</Link> page or just <Link to='/contact'>drop us a line</Link>.</p>
        </div>
        <FAQs faqs={HYBRID}/>

        <CTA/>

      </main>
    </Layout>
  )
}
